import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

import axios from 'axios';
import React from 'react';
import ReactDOM from 'react-dom';
import Modal from 'react-modal';
import { ThemeProvider } from 'styled-components';

import App from './App';
import login from './login';
import logout from './logout';

// IE9 support
Modal.setAppElement(document.getElementById('root'));

export const theme = {
  colors: {
    primary: '#ffb600',
    turleGreen: '#6fb24f',
    txt: '#474747',
  },
};

// Send bearer token along all axios requests
axios.interceptors.request.use(
  function (config) {
    return {
      ...config,
      headers: {
        ...config.headers,
        Authorization: `Bearer ${localStorage.getItem('auth')}`,
      },
    };
  },
  function (error) {
    return Promise.reject(error);
  }
);

// Redirect to login screen when 401 response with token expired message
axios.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error.response && 401 === error.response.status) {
      localStorage.clear();
      login();
    } 
    // concurrent
    else if (error.response && 409 === error.response.status) {
      logout();
    }
    else {
      return Promise.reject(error);
    }
  }
);

ReactDOM.render(
  <ThemeProvider theme={theme}>
    <App />
  </ThemeProvider>,
  document.getElementById('root')
);
