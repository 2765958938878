import styled from 'styled-components';
import React from 'react';
import { Field } from 'formik';

import CloseIcon from '../../assets/baseline-close-24px.svg?react';

export const Button = styled.button`
  border: 1px solid #e0301e;
  color: ${({ primary }) => (primary ? 'white' : '#e0301e')};
  background-color: ${({ primary }) => (primary ? '#e0301e' : 'white')};
  padding: 7px 5px 7px 5px;
  width: ${({ width }) => (width ? width : '150px')};
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  outline: none;
  margin-right: 10px;

  :disabled {
    background-color: white;
    color: #cac7c6;
    border: 1px solid #cac7c6;
    cursor: none;
  }

  :hover {
    cursor: pointer;
  }
`;

export const CancelBtn = styled.button`
  border: none;
  border-bottom: 1px solid #474747;
  color: #474747;
  background-color: white;
  padding: 7px 5px 7px 5px;
  width: 150px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  outline: none;
  margin-left: 10px;
  margin-right: 10px;

  :disabled {
    background-color: white;
    color: #474747;
    border: 1px solid #474747;
    cursor: none;
  }

  :hover {
    cursor: pointer;
  }
`;

// <a> which looks like a button
export const A = styled.a`
  border: 1px solid #e0301e;
  color: ${({ primary }) => (primary ? 'white' : '#e0301e')};
  background-color: ${({ primary }) => (primary ? '#e0301e' : 'white')}
  padding: 7px 5px 7px 5px;
  width: 150px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;  
  outline: none;
`;

export const Box = styled.div`
  background-color: white;
  padding: 20px;
  margin-top: 10px;
  margin-bottom: 50px;
  border-bottom: ${(props) =>
    props.border ? `3px solid ${props.border}` : 'none'};
`;

export const Status = styled.div`
  width: 120px;
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: 14px;
  border-radius: 5px;
  text-align: center;
  background-color: ${({ status }) =>
    status === 'loading' || status === 'PENDING' || status === 'LOADING'
      ? '#eb8c00'
      : status === 'ready' || status === 'DONE'
      ? '#6fb24f'
      : '#afa8a9'};
  color: white;
`;

export const OtherStatusLabel = styled.div`
  width: 120px;
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: 14px;
  border-radius: 5px;
  text-align: center;
  background-color: ${({ status }) => {
    switch (status) {
      case 'loading':
        return '#afa8a9';
      case 'ready':
        return '#6fb24f';
      case 'corrected':
        return '#98d67a';
      default:
        return '#e0301e';
    }
  }};
  color: white;
`;

export const Input = styled(Field)`
  font-size: 16px;
  border: solid 1px #979797;
  padding: 5px 10px;
  width: 400px;
`;

export const Label = styled.div`
  font-family: HelveticaNeue;
  font-size: 15px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -0.02px;
  color: #474747;
  margin-bottom: 8px;
`;

const LabelInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
`;

export const InputContainer = ({ children, label }) => (
  <LabelInputContainer>
    <Label>{label}</Label>
    {children}
  </LabelInputContainer>
);

// Icon as a button
const InvisibleBtn = styled.button`
  background-color: Transparent;
  cursor: pointer;
  border: none;

  &:focus {
    outline: none;
  }
`;

export const IconBtn = ({ icon: Icon, onClick }) => (
  <InvisibleBtn onClick={onClick}>
    <Icon />
  </InvisibleBtn>
);

// Modal Header
export const StyledModalHeader = styled.div`
  color: #e0301e;
  font-size: 18px;
  font-weight: bold;
  flex-grow: 0.95;
`;

const StyledModalHeaderContainer = styled.div`
  display: flex;
  align-items: center;
  border-bottom: 1px solid #474747;
  padding-bottom: 10px;
  margin-bottom: 10px;
`;
export const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  z-index: 999;
  height: 100%;
`;

export const ModalBody = styled.div`
  display: flex;
  flex-direction: column;
  flex: auto;
  overflow: scroll;
`;

export const ModalHeader = ({ onClose, children }) => (
  <StyledModalHeaderContainer>
    <StyledModalHeader>{children}</StyledModalHeader>
    <IconBtn onClick={onClose} icon={CloseIcon} />
  </StyledModalHeaderContainer>
);

export const ModalFooter = styled.div`
  margin-top: 10px;
  display: flex;
  justify-content: flex-end;
`;

export const ReportTable = styled.div`
  display: flex;
  flex-direction: column;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
`;

export const ReportTableContent = styled.div`
  height: 500px;
  overflow: auto;
`;

export const ReportTableHeader = styled.div`
  flex: 1;
  padding: 11px 38px;
  background-color: #464646;
  display: flex;
  color: white;
`;

export const SpanFiller = styled.span`
  font-weight: 300;
  opacity: 0.5;
`;

export const ReportTableRow = styled.div`
  flex: 1;
  padding: 13px 38px;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  margin-bottom: 2px;
  border-bottom: 1px solid #d8d8d8;
`;

export const ReportTableCell = styled.div`
  width: ${({ width }) => (width ? width : '25%')};
`;

export const ReportTableCellEllipsis = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
`;
